import { DocumentClient } from "aws-sdk/clients/dynamodb";
import AWS from "aws-sdk";

const documentClient = new AWS.DynamoDB.DocumentClient();

export function getRandomString() {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 6; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }
  return result;
}

export async function fetchData(tableName, indexName, indexValue) {
  const params = {
    TableName: tableName,
    IndexName: indexName,
    KeyConditionExpression: "#jobId = :jobIdVal",
    ExpressionAttributeNames: {
      "#jobId": "jobId",
    },
    ExpressionAttributeValues: {
      ":jobIdVal": indexValue,
    },
  };

  try {
    const result = await documentClient.query(params).promise();
    console.log("result", result);
    return result.Items;
  } catch (error) {
    console.error("Error retrieving records:", error);
    throw error;
  }
}
