import React, { useContext, useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import uuid from "react-uuid";
import { MainContext } from "../../Context/MainContext";
import Dropzone from "react-dropzone-uploader";
import AWS from "aws-sdk";
import { FcAddImage } from "react-icons/fc";
import { HiPlus } from "react-icons/hi";
import { acceptFileType } from "../../Config/config";
import { errorToast } from "../../Utils/showToast";

const Preview = ({ meta }) => {
  const { name, percent, status } = meta;
  return (
    <span
      style={{
        alignSelf: "flex-start",
        margin: "10px 3%",
        fontFamily: "Helvetica",
      }}
    >
      {name}, {Math.round(percent)}%, {status}
    </span>
  );
};

function UploadContainer({ onChange }) {
  const { mainState, mainReducer } = useContext(MainContext);
  const inputRef = useRef(null);
  const uploadDivRef = useRef(null);

  const [dragOver, setDragOver] = useState(false);

  // const uploadData = async () => {
  //   console.log(mainState?.files);
  //   for (const item of mainState?.files) {
  //     await uploadFile(item);
  //   }
  // };

  const handleChangeStatus = ({ meta }, status) => {
    console.log("File State", status, meta?.name, meta);
  };

  // const handleSubmit = async (files, allFiles) => {
  //   console.log("Files Data", files, allFiles);
  //   mainReducer({
  //     type: "SET_LOADER",
  //     payload: true,
  //   });

  //   for (const item of files) {
  //     await uploadFile({ id: uuid() + "_" + mainState?.jobIndex, file: item });
  //     allFiles.map((file) => {
  //       if (file?.file?.name === item?.file?.name) {
  //         file.remove();
  //       }
  //     });
  //   }
  // };
  const fileProcessing = (files) => {
    console.log("File Processing", files);
    for (var i = 0; i < files.length; i++) {
      onChange({ id: uuid() + "_" + mainState?.jobIndex, file: files[i] });
    }
  };

  const handleFile = async (e) => {
    console.log("File Processing", e.target.value);
    if (e.target.value.length > 0) {
      fileProcessing(e.target.files);
      //   if (e) {
      //     e.target.value = null;
      //   }
    }
  };

  useEffect(() => {
    uploadDivRef.current.addEventListener("dragover", handleDragOver);
    uploadDivRef.current.addEventListener("drop", handleDrop);
    uploadDivRef.current.addEventListener("dragenter", handleDragEnter);
    uploadDivRef.current.addEventListener("dragleave", handleDragLeave);

    return () => {
      uploadDivRef.current?.removeEventListener("dragover", handleDragOver);
      uploadDivRef.current?.removeEventListener("drop", handleDrop);
      uploadDivRef.current?.removeEventListener("dragenter", handleDragEnter);
      uploadDivRef.current?.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target !== uploadDivRef.current) {
      setDragOver(true);
    }
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (e.target === uploadDivRef.current) {
      setDragOver(false);
    }
  };
  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);

    const { files } = e.dataTransfer;

    if (files && files.length) {
      fileProcessing(files);
    }
  };
  return (
    <div
      className={`uploadFileDiv ${dragOver ? "dragActive" : ""}`}
      onClick={() => inputRef.current.click()}
      ref={uploadDivRef}
    >
      <FcAddImage size={80} />
      <span style={{ fontSize: 12 }}>Upload pdf, doc, excel here.</span>
      <label className="btn btn-outline-secondary btn-sm mt-4 d-flex align-items-center justify-content-center">
        <HiPlus /> Select File
      </label>
      <input
        type="file"
        id="inputFile"
        className="form-control d-none"
        multiple
        onChange={handleFile}
        ref={inputRef}
        accept={acceptFileType.join(", ")}
        onClick={(e) => {}}
      />
    </div>
    // <Dropzone
    //   // getUploadParams={getUploadParams}
    //   onChangeStatus={handleChangeStatus}
    //   onSubmit={handleSubmit}
    //   PreviewComponent={Preview}
    //   styles={{ dropzone: { minHeight: 200, maxHeight: 250, margin: 10 } }}
    // />
  );
}

export default UploadContainer;
