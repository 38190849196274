import React, { useContext, useEffect } from "react";
import { MainContext } from "../../Context/MainContext";
import { fetchData } from "../../Data-service/common";

const useDataFetcher = () => {
  const { mainState, mainReducer } = useContext(MainContext);

  const tableName = "MunimInvoiceEntry";
  const indexName = "jobId-index";
  const indexValue = mainState?.jobIndex;

  useEffect(() => {
    if (mainState?.jobIndex) fetchData(tableName, indexName, indexValue);
    const interval = setInterval(() => {
      // Call your function here
      fetchData(tableName, indexName, indexValue)
        .then((items) => {
          if (items?.length) {
            console.log("File Data", items);
            mainReducer({
              type: "SET_INVOICE_LIST",
              payload: [...mainState?.invoiceList, ...items],
            });
            mainReducer({
              type: "SET_LOADER",
              payload: false,
            });
          }
        })
        .catch((error) => {
          console.error("An error occurred:", error);
        });
    }, 3000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, [mainState?.jobIndex]);
};

export default useDataFetcher;
