import { createContext, useEffect, useReducer } from "react";
import AWS from "aws-sdk";
AWS.config.update({
  accessKeyId: "AKIAY5MPVQUH7LIH25XA",
  secretAccessKey: "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",
  region: "us-west-1",
});
export const MainContext = createContext({});

const initialState = {
  files: [],
  jobIndex: null,
  invoiceList: [],
  loader: false,
  readyForUpload: false,
};

const Reducer = (state, action) => {
  const actions = {
    SET_FILE: {
      ...state,
      files: action?.payload,
    },
    SET_JOB_INDEX: {
      ...state,
      jobIndex: action?.payload,
    },
    SET_INVOICE_LIST: {
      ...state,
      invoiceList: action?.payload,
    },
    SET_LOADER: {
      ...state,
      loader: action?.payload,
    },
    SET_READY_FOR_UPLOAD: {
      ...state,
      readyForUpload: action?.payload,
    },
  };
  return actions[action.type];
};

const MainProvider = (props) => {
  useEffect(() => {}, []);

  const [mainState, mainReducer] = useReducer(Reducer, initialState);

  return (
    <MainContext.Provider value={{ mainState, mainReducer }}>
      {props.children}
    </MainContext.Provider>
  );
};
export default MainProvider;
