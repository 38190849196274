import React, { useContext, useEffect } from "react";
import VerticalNavBar from "../Components/MainComponent/VerticalNavBar";
import Listview from "../Components/MainComponent/Listview";
import { Container } from "react-bootstrap";
import { getRandomString } from "../Data-service/common";
import { MainContext } from "../Context/MainContext";
import useDataFetcher from "../hooks/subscriptions/useDataFetcher";

function Main(props) {
  const { mainState, mainReducer } = useContext(MainContext);

  useDataFetcher();
  useEffect(() => {
    mainReducer({
      type: "SET_JOB_INDEX",
      payload: getRandomString(),
    });
  }, []);
  console.log("Job Index", window.location);
  return (
    <div className={`row mx-0 app-container`}>
      {window.location.href.includes("#iframe") ? (
        <></>
      ) : (
        <div className="col-md-1 text-center p-0">
          <VerticalNavBar />
        </div>
      )}
      <div className="col-md-11 mb-5">
        <Listview />
      </div>
    </div>
  );
}

export default Main;
