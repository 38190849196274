import React, { useContext, useEffect, useMemo, useState } from "react";
import UploadContainer from "./UploadContainer";
import { MainContext } from "../../Context/MainContext";
import { Container, Table } from "react-bootstrap";
import DataGrid, { textEditor } from "react-data-grid";
import { exportToCsv, exportToExcel } from "./ExportUtiles";
import Button from "../CommonComponent/Button";
import AWS from "aws-sdk";
import { useBeforeRender } from "../../hooks/useBeforeRender";
import uuid from "react-uuid";
import Loading from "../CommonComponent/Loading";
import { FcCheckmark } from "react-icons/fc";
import { AiOutlineClose } from "react-icons/ai";

const columns = [
  {
    key: "invoice_no",
    name: "Invoice number",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "date",
    name: "Date",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "customer",
    name: "Customer Name",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "supplier",
    name: "Supplier Name",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "igst",
    name: "IGST",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "cgst",
    name: "CGST",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "sgst",
    name: "SGST",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "tax",
    name: "Total Tax",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "discount",
    name: "Total discount",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "hsn",
    name: "HSN number",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "taxable",
    name: "Taxable amount",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
  {
    key: "final",
    name: "Final total",
    resizable: true,
    frozen: true,
    editor: textEditor,
    headerCellClass: "tableHeader",
  },
];

function sortColumn(sortColumn) {
  switch (sortColumn) {
    case "totalTax":
      return (a, b) => {
        return a[sortColumn] - b[sortColumn];
      };
    default:
      throw new Error(`unsupported sortColumn: "${sortColumn}"`);
  }
}

function Listview({ onExport, children }) {
  const { mainState, mainReducer } = useContext(MainContext);

  const [files, setFiles] = useState([]);
  const [rows, setRows] = useState();
  const [selectedRows, setSelectedRows] = useState();
  const [sortColumns, setSortColumns] = useState([]);

  const filterData = mainState?.invoiceList?.filter(
    (item) => item?.jobId === mainState?.jobIndex
  );

  useBeforeRender(() => {
    window.addEventListener("error", (e) => {
      if (e) {
        const resizeObserverErrDiv = document.getElementById(
          "webpack-dev-server-client-overlay-div"
        );
        const resizeObserverErr = document.getElementById(
          "webpack-dev-server-client-overlay"
        );
        if (resizeObserverErr)
          resizeObserverErr.className = "hide-resize-observer";
        if (resizeObserverErrDiv)
          resizeObserverErrDiv.className = "hide-resize-observer";
      }
    });
  }, []);

  function handleFill({ columnKey, sourceRow, targetRow }) {
    return { ...targetRow, [columnKey]: sourceRow[columnKey] };
  }

  function handlePaste({
    sourceColumnKey,
    sourceRow,
    targetColumnKey,
    targetRow,
  }) {
    const incompatibleColumns = ["email", "zipCode", "date"];
    if (
      sourceColumnKey === "avatar" ||
      ["id", "avatar"].includes(targetColumnKey) ||
      ((incompatibleColumns.includes(targetColumnKey) ||
        incompatibleColumns.includes(sourceColumnKey)) &&
        sourceColumnKey !== targetColumnKey)
    ) {
      return targetRow;
    }

    return { ...targetRow, [targetColumnKey]: sourceRow[sourceColumnKey] };
  }

  function handleCopy({ sourceRow, sourceColumnKey }) {
    if (window.isSecureContext) {
      navigator.clipboard.writeText(sourceRow[sourceColumnKey]);
    }
  }

  const sortedRows = useMemo(() => {
    if (sortColumns.length === 0) return rows;

    return [...rows].sort((a, b) => {
      for (const sort of sortColumns) {
        const comparator = sortColumn(sort.columnKey);
        const compResult = comparator(a, b);
        if (compResult !== 0) {
          return sort.direction === "ASC" ? compResult : -compResult;
        }
      }
      return 0;
    });
  }, [rows, sortColumns]);

  function ExportButton({ onExport, children }) {
    const [exporting, setExporting] = useState(false);
    return (
      <button
        disabled={exporting}
        onClick={async () => {
          setExporting(true);
          await onExport();
          setExporting(false);
        }}
        className="exportBtn "
      >
        {exporting ? "Exporting" : children}
      </button>
    );
  }

  const onChange = ({ id, file }) => {
    setFiles((prev) => [...prev, { id: uuid(), name: file?.name, file: file }]);
    mainReducer({
      type: "SET_READY_FOR_UPLOAD",
      payload: true,
    });
  };

  const uploadFile = () => {
    mainReducer({
      type: "SET_READY_FOR_UPLOAD",
      payload: false,
    });
    for (var i = 0; i < files.length; i++) {
      const uploadParams = {
        Bucket: "munim",
        Key: files[i]?.id + "_" + mainState?.jobIndex,
        ContentType: files[i]?.file?.type,
        Body: files[i]?.file,
      };
      const upload = new AWS.S3.ManagedUpload({ params: uploadParams });
      upload.on("httpUploadProgress", (progress) => {
        const { loaded, total } = progress;
        const percentage = Math.round((loaded / total) * 100);
        // Update your React component state with the percentage value
      });
      upload.send((err, data) => {
        if (err) {
          console.error(err);
          // Handle the error
        } else {
          // Handle the successful upload
        }
      });
    }
  };
  const gridElement = (
    <DataGrid
      columns={columns}
      rows={mainState?.invoiceList || []}
      rowGetter={(i) => rows[i]}
      onRowsChange={setRows}
      onFill={handleFill}
      onCopy={handleCopy}
      onPaste={handlePaste}
      rowHeight={30}
      selectedRows={selectedRows}
      onSelectedRowsChange={setSelectedRows}
      rowClass={(row, index) => "tableStyle"}
      direction={"ltr"}
      onCellClick={(args, event) => {
        event.preventGridDefault();
        args.selectCell(true);
      }}
      style={{
        backgroundColor: "#ffffff",
        // borderWidth: 0,
        // borderTopWidth: 1,
      }}
    />
  );

  return (
    <Container fluid className="addContainer">
      <div className="dropContainer">
        <UploadContainer onChange={onChange} />
      </div>
      {files && files?.length > 0 && (
        <Table bordered responsive className="table table--attachment">
          <thead className="table tableHeader">
            <tr>
              <td colSpan={"2"}>Name</td>
            </tr>
          </thead>
          <tbody>
            {files?.map((file) => {
              const isFileUploaded = mainState?.invoiceList?.filter(
                (item) => item?.fileId === file?.id
              );
              return (
                <tr key={file?.id}>
                  <td width={"100%"}>
                    {file?.name}
                    {"   "}
                    {mainState?.readyForUpload ? (
                      <>Ready</>
                    ) : isFileUploaded?.length > 0 ? (
                      <FcCheckmark size={25} />
                    ) : (
                      <Loading />
                    )}
                  </td>

                  <td
                    className="cancleBtn"
                    onClick={() => {
                      const filterData = files?.filter(
                        (item) => item?.id !== file?.id
                      );
                      setFiles([...filterData]);
                    }}
                  >
                    Cancle
                  </td>
                </tr>
              );
            })}
            <button className="exportBtn mt-2" onClick={uploadFile}>
              Submit
            </button>
          </tbody>
        </Table>
      )}

      <div className="exportContainer">
        <ExportButton
          onExport={() => exportToCsv(gridElement, "CommonFeatures.csv")}
        >
          Export to CSV
        </ExportButton>
      </div>
      {gridElement}
    </Container>
  );
}

export default Listview;
