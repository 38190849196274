import Main from "./pages/Main";
import "./style/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import MainProvider from "./Context/MainContext";
import "@silevis/reactgrid/styles.css";
import "react-data-grid/lib/styles.css";
import { Helmet } from "react-helmet";
import "react-dropzone-uploader/dist/styles.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import IFrame from "./pages/IFrame";
function App() {
  return (
    <MainProvider>
      <Router>
        <Routes>
          <Route path="/" exact element={<Main />} />
          {/* <Route path="/iframe" element={<IFrame />} /> */}
        </Routes>
      </Router>
    </MainProvider>
  );
}

export default App;
