import AWS from "aws-sdk";

const CONFIG = {
  CHATGPT: "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",
};

export default CONFIG;

export const acceptFileType = [
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
];
