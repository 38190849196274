import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import SimpliLogo from "../../assets/Simpeli-Logo.png";

function VerticalNavBar() {
  // const navArray = ["Users"];

  return (
    <Navbar className="navContainer w-100 justify-content-center">
      <img src={SimpliLogo} />
    </Navbar>
  );
}

export default VerticalNavBar;
