import { cloneElement } from "react";
import XLSX from "sheetjs-style";

async function getGridContent(gridElement) {
  const { renderToStaticMarkup } = await import("react-dom/server");
  const grid = document.createElement("div");
  grid.innerHTML = renderToStaticMarkup(
    cloneElement(gridElement, {
      enableVirtualization: false,
    })
  );

  return {
    head: getRows(".rdg-header-row"),
    body: getRows(".rdg-row:not(.rdg-summary-row)"),
    foot: getRows(".rdg-summary-row"),
  };

  function getRows(selector) {
    return Array.from(grid.querySelectorAll(selector)).map((gridRow) => {
      return Array.from(gridRow.querySelectorAll(".rdg-cell")).map(
        (gridCell) => gridCell.innerText
      );
    });
  }
}

function serialiseCellValue(value) {
  if (typeof value === "string") {
    const formattedValue = value.replace(/"/g, '""');
    return formattedValue.includes(",")
      ? `"${formattedValue}"`
      : formattedValue;
  }
  return value;
}

function downloadFile(fileName, data) {
  const downloadLink = document.createElement("a");
  downloadLink.download = fileName;
  const url = URL.createObjectURL(data);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
}

export async function exportToCsv(gridElement, fileName) {
  const { head, body, foot } = await getGridContent(gridElement);
  const content = [...head, ...body, ...foot]
    .map((cells) => cells.map(serialiseCellValue).join(","))
    .join("\n");

  downloadFile(
    fileName,
    new Blob([content], { type: "text/csv;charset=utf-8;" })
  );
}

export const exportToExcel = async ({
  exportData,
  fileName,
  skipDefaultTitle = true,
}) => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const workbook = XLSX.utils.book_new();

  console.log("Export Data", exportData);

  Object.keys(exportData)?.forEach((key) => {
    const ws = XLSX.utils.json_to_sheet(exportData[key], {
      skipHeader: skipDefaultTitle,
    });
    XLSX.utils.book_append_sheet(workbook, ws, key);
  });

  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  downloadFile(fileName + fileExtension, data);
};
